<template>
  <div>
        <CustomizeNavBar></CustomizeNavBar>
        <div v-if="isLoading">
        <LoaderComp/>
        </div>
        <!-- Children Routes -->
        <div v-else>
            <div v-if="invalidKey">
                <InvalidKeyPage/>
            </div>
            <div v-else>
                <router-view></router-view>
            </div>
        </div>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'
import globalMixin from "../../GlobalMixins/globalMixin.js"
import LoaderComp from "../customize/component/LoaderComp.vue";
// import InvalidKeyPage from "../quiz/pages/InvalidKeyPage.vue"
import InvalidKeyPage from "../quizPreviewComp/InvalidKeyPage.vue"
import CustomizeNavBar from "./components/customizeNavBar.vue"
export default {
  components:{
    LoaderComp,
    InvalidKeyPage,
    CustomizeNavBar
  },
  mixins: [globalMixin],
  data(){
    return {
      isLoading:false,
      invalidKey:false
    }
  },

methods:{
  ...mapMutations(["ResetQuizId","ResetQuizKey","setQuizKey","setQuizId"]),
   navigateToQuizDesign(){
      window.location.href = '/dashboard'
    },

},
mounted(){
},
}
</script>
<style  >

.navbar{
      background: #4d1b7e;
  color:#ffffff;
  box-shadow: 0px 0px 15px rgba(10, 4, 17, 0.2);
  display: block;
}

.navbar__middle{
     flex: 1;
}
/* .navbar__middle:hover{
    background: rgba(0, 0, 0, 0.16);
} */

.navbar__icon-container{
    background:  rgb(179 175 182 / 30%);
      height: 30px;
      width: 30px;
}
.navbar__icon-container.large{
     cursor: pointer;
        background: transparent;
        height: 40px;
        width: 40px;
}
.navbar__icon-container.active{
     background:  rgb(179 175 182 / 30%);
}




.navbar__title {
  font-size: 14px;
}
.routeLink{
  position: relative;
}
.router-link-active {
  border-bottom : 2px solid #ffffff;

}

.modal{
  z-index: 1000001 !important;
}
</style>
